<template>
  <div class="jh-container">
    <div class="jh-ui-header">
      <h1>회원검색</h1>
    </div>
 
    <div class="jh-search-form">
      <table>
        <colgroup>
          <col width="50px">
          <col width="120px">
          <col width="45px">
          <col width="120px">
          <col width="65px">
          <col width="120px">
          <col width="55px">
          <col width="120px">
          <!-- <col width="75px">
          <col width="100px">
          <col width="75px">
          <col width="100px">
          <col width="85px">
          <col width="120px">
          <col width="75px">
          <col width="210px"> -->
          <col>
        </colgroup>
        <tr>
          <!-- <th><label>조회기간</label></th>
          <td>
            <div class="jh-cols">                        
              <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dates[0]" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue"></v-text-field>
                </template>
                <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :day-format="mixin_getDate" @change="startDate"></v-date-picker>
              </v-menu>
              <span class="jh-unit">~</span>                       
              <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dates[1]" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue"></v-text-field>
                </template>
                <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="endDate"></v-date-picker>
              </v-menu>
            </div>
          </td> -->
          <th><label>권한그룹</label></th>
          <td>
            <v-select class="jh-form" :items="dropGroupItems" item-text="NM" item-value="CD" v-model="ATRT_GROUP_ID"></v-select>
          </td>
          <th><label>ID</label></th>
          <td>
            <v-text-field class="jh-form" v-model="USER_ID" @keyup.enter="search"></v-text-field>
          </td>
          <th><label>회원명</label></th>
          <td>
            <v-text-field class="jh-form" v-model="USER_NM" @keyup.enter="search"></v-text-field>
          </td>
          <th><label>구분</label></th>
          <td>
            <v-select class="jh-form" :items="dropYn" item-text="title" item-value="value" v-model="BRCH_CD"></v-select>
          </td>
          <!-- <th><label>최근접속IP</label></th>
          <td>
            <v-text-field class="jh-form" v-model="CNNCT_IP" @keyup.enter="search"></v-text-field>
          </td>
          <th><label>정렬조건</label></th>
          <td>
            <v-radio-group class="jh-radio" row v-model="radioGroupSelected">
              <v-radio v-for="(rad01, index) in radioGroup" :key="radioGroup[index].text" :label="rad01.text" :value="rad01.value"></v-radio>
            </v-radio-group>
          </td> -->
          <td class="has-search"><v-btn class="jh-btn is-search" id="btnSearch" @click="search">조회</v-btn></td>
        </tr>
      </table>            
    </div>

    <div class="jh-form-wrap">
      <div class="jh-ui-header">
        <h2>회원 리스트</h2>
        <div class="is-right">
          <v-btn class="jh-btn is-border-blue" id="btnNew" @click="userAdd" >신규</v-btn>
          <!-- <v-btn class="jh-btn is-border-blue" id="btnNew" @click="userAdd" v-if="this.mixin_set_btn(this.$options.name, 'btnNew')">신규</v-btn> -->
          <v-btn class="jh-btn is-red" id="btnDelete" @click="userDelete" >삭제</v-btn>
          <!-- <v-btn class="jh-btn is-light" id="btnDetail" @click="sendDetailInfo" v-if="this.mixin_set_btn(this.$options.name, 'btnDetail')">상세</v-btn> -->
          <!-- <v-btn class="jh-btn is-fill" id="btnNewAttr" @click="groupAdd" v-if="this.mixin_set_btn(this.$options.name, 'btnRegiGroup')">소속등록</v-btn> -->
        </div>
      </div>
      <data-tables
        ref="dataTable"
        dataTableClass=""
        :data-table-options="dataTableOptions"
        @click:row="getListSel"
        :paginationOptions="paginationOptions"
        v-model="selGroupCd"
      ></data-tables>      
    </div>

    <div class="jh-form-wrap">
      <div class="jh-ui-header">
        <div class="is-left">
          <h2>회원 상세정보</h2>

        </div>
        <div class="is-right">
          <div class="jh-info-txt is-fill is-red is-mr-5">상담사 초기화 비밀번호는 <em>"1111"</em> 입니다.</div>
          <v-btn class="jh-btn is-light"  id="btnReset" @click="resetBtn">비밀번호 초기화</v-btn>
          <v-btn class="jh-btn is-del"  id="btnUnLock" @click="unlockBtn">잠금해제</v-btn>
          <span class="jh-split"></span>
          <v-btn class="jh-btn is-main"  id="btnSave01" @click="saveManual">저장</v-btn>
        </div>
      </div>

      <div class="jh-cols">
        <table class="jh-tbl-detail">
          <colgroup>
            <col width="100px">
            <col>
            <col width="100px">
            <col>
            <!-- <col width="100px">
            <col> -->
            <!-- <col width="110px">
            <col> -->
          </colgroup>
          <tbody>
            <tr style="margin-top: 80px;">
              <th><label>ID</label></th>
              <td>
                <v-text-field class="jh-form is-trns" v-model="detail.USER_ID" disabled></v-text-field>
              </td>
              <th><label class="is-required">권한그룹</label></th>
              <td>
                <v-select class="jh-form" :items="dropGroupItems2" item-text="NM" item-value="CD" v-model="detailObj.ATRT_GROUP_ID" :disabled="atrt_disabled"></v-select>
              </td>         
            </tr>            
            <tr>
              <th><label>회원명</label></th>
              <td>
                <v-text-field :class="[regType == '02' ? 'jh-form' : 'jh-form is-trns']" v-model="detail.USER_NM" :disabled="regType == 'auto'"></v-text-field>
              </td>
              <th><label>주민번호</label></th>
              <td>
                <v-text-field :class="[regType == '02' ? 'jh-form' : 'jh-form is-trns']" v-model="detail.USER_SSN_MASK" :disabled="regType == 'auto'"></v-text-field>
              </td>              
            </tr>
            <tr>
              <th><label>연락처1</label></th>
              <td>
                <v-text-field :class="[regType == '02' ? 'jh-form' : 'jh-form is-trns']" v-model="detail.HP_NO" :disabled="regType == 'auto'"></v-text-field>
              </td>
              <th><label>연락처2</label></th>
              <td>
                <v-text-field :class="[regType == '02' ? 'jh-form' : 'jh-form is-trns']" v-model="detail.IPT_NO" :disabled="regType == 'auto'"></v-text-field>
              </td>                            
            </tr>
            <tr>
              <th><label class="is-required">구분</label></th>
              <td>
                <v-select class="jh-form" :items="dropYn" item-text="title" item-value="value" v-model="detailObj.BRCH_CD"></v-select>
              </td>              
              <th><label>소속사</label></th>
              <td>
                <v-text-field :class="[regType == '02' ? 'jh-form' : 'jh-form is-trns']" v-model="detail.CEN_TYPE_CD" :disabled="regType == 'auto'"></v-text-field>
              </td>               
            </tr>
            <tr>
              <th><label>지사</label></th>
              <td>
                <v-text-field :class="[regType == '02' ? 'jh-form' : 'jh-form is-trns']" v-model="detail.JISA" :disabled="regType == 'auto'"></v-text-field>
              </td>       
              <th><label>지점</label></th>
              <td>
                <v-text-field :class="[regType == '02' ? 'jh-form' : 'jh-form is-trns']" v-model="detail.JISUM" :disabled="regType == 'auto'"></v-text-field>
              </td>               
            </tr>            
            <tr>
              <th><label>은행</label></th>
              <td>
                <v-text-field :class="[regType == '02' ? 'jh-form' : 'jh-form is-trns']" v-model="detail.USER_BK" :disabled="regType == 'auto'"></v-text-field>
              </td>
              <th><label>계좌번호</label></th>
              <td>
                <v-text-field :class="[regType == '02' ? 'jh-form' : 'jh-form is-trns']" v-model="detail.USER_ACT" :disabled="regType == 'auto'"></v-text-field>
              </td>              
            </tr>
            <!-- <tr>
              <th><label>ID</label></th>
              <td>
                <v-text-field class="jh-form is-trns" v-model="detail.USER_ID" disabled></v-text-field>
              </td>
              <th><label>상담사명</label></th>
              <td>
                <v-text-field :class="[regType == '02' ? 'jh-form' : 'jh-form is-trns']" v-model="detail.USER_NM" :disabled="regType == 'auto'"></v-text-field>
              </td>
              <th><label>부서코드</label></th>
              <td>
                <v-text-field class="jh-form is-trns" v-model="detail.USER_ATTR_A" disabled></v-text-field>
              </td>
              <th><label>부서명</label></th>
              <td>
                <v-select v-if="regType == '02'" class="jh-form" :items="dropA" item-text="CD_NM" item-value="CD" placeholder="선택하세요" 
                  v-model="detail.USER_ATTR_A" @change="changeDept"></v-select>
                <v-text-field v-else class="jh-form is-trns" v-model="detail.ATTR_DIV_NM_A" disabled></v-text-field>
              </td>
            </tr>                                                
            <tr>
              <th><label>직책</label></th>
              <td>
                <v-text-field :class="[regType == '02' ? 'jh-form' : 'jh-form is-trns']" v-model="detail.USER_DUTY" :disabled="regType == 'auto'"></v-text-field>
              </td>
              <th><label>재직구분</label></th>
              <td>
                <v-select v-if="regType == '02'" class="jh-form" :items="employed" item-text="CD_NM" item-value="CD" placeholder="선택하세요" v-model="detail.EMPLOYED_YN_CD"></v-select>
                <v-text-field v-else :class="[regType == '02' ? 'jh-form' : 'jh-form is-trns']" v-model="detail.EMPLOYED_YN" :disabled="regType == 'auto'"></v-text-field>
              </td>
              <th><label>이메일</label></th>
              <td>
                <v-text-field :class="[regType == '02' ? 'jh-form' : 'jh-form is-trns']" v-model="detail.EMAIL_ADDR" :disabled="regType == 'auto'"></v-text-field>
              </td>
              <th><label>내선번호(IPT)</label></th>
              <td>
                <v-text-field :class="[regType == '02' ? 'jh-form' : 'jh-form is-trns']" v-model="detail.IPT_NO" :disabled="regType == 'auto'"></v-text-field>
              </td>
            </tr>
            <tr>
              <th><label>입사일</label></th>
              <td>
                <v-text-field :class="[regType == '02' ? 'jh-form' : 'jh-form is-trns']" v-model="detail.ENTCO_DT" :disabled="regType == 'auto'"></v-text-field>
              </td>
              <th><label>퇴사일</label></th>
              <td>
                <v-text-field :class="[regType == '02' ? 'jh-form' : 'jh-form is-trns']" v-model="detail.RETR_DT" :disabled="regType == 'auto'"></v-text-field>
              </td>
              <th><label>휴직일</label></th>
              <td>
                <v-text-field :class="[regType == '02' ? 'jh-form' : 'jh-form is-trns']" v-model="detail.LEAVE_DT" :disabled="regType == 'auto'"></v-text-field>
              </td>
              <th><label>복직일</label></th>
              <td>
                <v-text-field :class="[regType == '02' ? 'jh-form' : 'jh-form is-trns']" v-model="detail.REINSTATE_DT" :disabled="regType == 'auto'"></v-text-field>
              </td>
            </tr>
            <tr>
              <th><label>최종발령일</label></th>
              <td>
                <v-text-field :class="[regType == '02' ? 'jh-form' : 'jh-form is-trns']" v-model="detail.ASSIGN_DT" :disabled="regType == 'auto'"></v-text-field>
              </td>
              <th><label>최초등록일시</label></th>
              <td>
                <v-text-field class="jh-form is-trns" v-model="detail.REG_DTTM" disabled></v-text-field>
              </td>
              <th><label>최종수정일시</label></th>
              <td>
                <v-text-field class="jh-form is-trns" v-model="detail.UPD_DTTM" disabled></v-text-field>
              </td>
              <th><label>최근접속일시</label></th>
              <td>
                <v-text-field class="jh-form is-trns" v-model="detail.LAST_LOGIN_DT" disabled></v-text-field>
              </td>
            </tr> -->
          </tbody>
        </table>
        <table class="jh-tbl-detail is-col-fix" style="width: 780px;">
          <colgroup>
            <col width="100px">
            <col>
            <col width="100px">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th><label>고객의견</label></th>
              <td>
                <v-textarea class="jh-form" height="150px" v-model="detail.USER_OP"></v-textarea>
              </td>
              <th><label>메모</label></th>
              <td>
                <v-textarea class="jh-form" height="150px" v-model="detail.MEMO"></v-textarea>
              </td>              
            </tr>
            <!-- <tr>
              <th><label>내선번호(IPCC)</label></th>
              <td>
                <v-text-field class="jh-form" v-model="detailObj.IPCC_NO"></v-text-field>
              </td>
              <th><label class="is-required">사용여부</label></th>
              <td>
                <v-select class="jh-form" :items="dropYn" item-text="title" item-value="value" v-model="detailObj.USE_YN"></v-select>
              </td>
            </tr>
            <tr>
              <th><label>IP주소</label></th>
              <td>
                <v-text-field class="jh-form" v-model="detailObj.CNNCT_IP"></v-text-field>
              </td>
              <th><label>원격ID</label></th>
              <td>
                <v-text-field class="jh-form" v-model="detailObj.REMOTE_ID"></v-text-field>
              </td>
            </tr>
            <tr>
              <th><label>개인챗팅허용수</label></th>
              <td>
                <v-text-field class="jh-form" v-model="detailObj.MAX_CHAT_AGENT"></v-text-field>
              </td>
              <th><label></label></th>
              <td>
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>

    <v-dialog v-if="dialogE040106P01" max-width="720" persistent v-model="dialogE040106P01">
      <dialog-E040106P01 p01
        headerTitle="상담사 신규등록"
        :param="E040106P01Param"
        @hide="hideDialog('E040106P01')"
        @submit="submitDialog('E040106P01')"
      ></dialog-E040106P01>
    </v-dialog>

    <v-dialog v-if="dialogE040106P02" max-width="320" persistent v-model="dialogE040106P02">
      <dialog-E040106P01 p02
        headerTitle="상담사 상세정보"
        :param="E040106P02Param"
        @hide="hideDialog('E040106P02')"
        @submit="submitDialog('E040106P02')"
        @reset="resetDialog('E040106P02')"
        @unlock="unlockDialog('E040106P02')"
      ></dialog-E040106P01>
    </v-dialog>

    <v-dialog v-if="dialogE040106P03" max-width="280" persistent v-model="dialogE040106P03">
      <dialog-E040106P01 p03
        headerTitle="상담사 소속등록"
        :param="E040106P03Param"
        @hide="hideDialog('E040106P03')"
        @submit="submitDialog('E040106P03')"
      ></dialog-E040106P01>
    </v-dialog>

  </div>
</template>
  
  <script>
  import dataTables from "@/components/DataTables";
  import DialogE040106P01 from "./E040106P01.vue";
  import api from "@/store/apiUtil";
  import {mixin} from "@/mixin/mixin";
  import gibberish from "@/mixin/gibberish-aes_mixin";
  import { eventBus } from "@/store/eventBus.js";
  import { mapGetters } from 'vuex';
  
  export default {
    name: "MENU_E999991", //name은 'MENU_' + 파일명 조합
    components: {
      DialogE040106P01,
      dataTables
    },
    mixins:[mixin],
    data() {
      return {
        selGroupCd : [],
        E040106P01Param : {},
        E040106P02Param : {},
        E040106P03Param : {},
        ATTR_DIV_CD: '',
        dialogE040106P01: false,
        dialogE040106P02: false,
        dialogE040106P03: false,
        menuStartDate: false,
        menuEndDate: false,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        dates: [
          '',
          '',
        ],
        radioGroup: [
          {
            text: "상담사명",
            value : "USER_NM",
          },
          {
            text: "최근접속일시",
            value : "JOIN_DATE",
          },
        ],
        radioGroupSelected: "USER_NM",
        
        dropYn:[
          { title: "전체", value:"" },
          { title: "설계사", value:"S" },
          { title: "딜러", value:"D" },
          { title: "일반", value:"N" },
        ],
        dropGroupItems:[
          {NM: "전체", CD:""},
        ],
        dropGroupItems2: [
          {NM: "전체", CD: ""},
        ],
        dropA:[{CD_NM : "전체", CD : ""}],
        dropB:[{CD_NM : "전체", CD : ""}],
        dropC:[{USER_ID: '', USER_NM: '전체'}],
        dt_dropB:[{CD_NM : "전체", CD : ""}],

        gridDataHeaders: [
          { text: "No", align: "center", value: "ROWNUM", width: "30px", },
          { text: "회원명", value: "USER_NM",  align: "center", width: "90px", },
          { text: "ID", value: "USER_ID", align: "center", width: "90px", },
          { text: "주민번호", value: "USER_SSN_MASK", align: "center", width: "120px", },
          { text: "연락처1", value: "HP_NO", align: "center", width: "120px", },
          { text: "연락처2", value: "IPT_NO", align: "center", width: "120px", },
          { text: "구분", value: "BRCH_CD", align: "center", width: "80px", },
          { text: "소속사", value: "CEN_TYPE_CD", align: "center", width: "120px", },
          { text: "지사", value: "JISA", align: "center", width: "120px", },
          { text: "지점", value: "JISUM", align: "center", width: "120px", },
          { text: "은행", value: "USER_BK", align: "center", width: "120px", },
          { text: "계좌번호", value: "USER_ACT", align: "center", width: "120px", },
          // { text: "고객의견", value: "USER_OP", align: "center", width: "80px", },
          // { text: "메모", value: "MEMO", align: "center", width: "70px" },

        ],
        page: 1,
        pageCount: 0,
        itemsPerPage: 20,
        totalVisible: 10,
        textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
        textareaValue: "200자 제한!",
  
        //v-model
        USER_ID_KEY :'',
        ASP_NEWCUST_KEY:'001',
        USE_YN :'',
        BRCH_CD :'',
        USER_ID : '',
        USER_NM : '',
        USER_NICK : '',
        CNNCT_IP:'',
        ATRT_GROUP_ID:'',
        USER_ATTR_A :'',
        USER_ATTR_B :'',
        USER_ATTR_C :'',
        SORT_ORDR : '',

        // 상세
        detail: {},
        detailObj: {},
        DT_USER_ATTR_A: '',     // 부서명
  
        ADMIN_ID : "20190125141939798TWB52868",

        dataTableOptions: {
          fixedHeader: true,
          hideDefaultFooter: true,
          height: '380px',
          items: undefined,
          itemKey: 'ROWNUM',
          itemsPerPage: 50,
          noDataText: "조회된 결과가 없습니다.",
          page: 1,
          singleSelect: true,
          disableSort: true,
          load: undefined
          // load: this.searchUserInfo
        },
        paginationOptions: {
            totalVisible: 10
        },
  
        requestData: {},

        FIRST_INQRY_YN: "Y",

        regType: "",
        regArr: [],
        employed: [{CD: "", CD_NM: "선택"}],

        disabeld_A: false,
        atrt_disabled: false,
      };
  
    },
    created() { 
      this.dataTableOptions.headers = this.gridDataHeaders;
    },
    mounted() {
      this.dataTableOptions.headers = this.gridDataHeaders;

      this.init();
      this.getList();
      this.getRegType();
      this.getSelBoxList();
    },
    computed: {
      dataTableInstance: function() {
        return this.$refs.dataTable;
      },
      initHeaders(){
        return {
          SERVICE : 'setting.agent.manage',
          METHOD : "",
          TYPE : 'BIZ_SERVICE',
        };
      },
      ...mapGetters({
        geUserRole: "userStore/GE_USER_ROLE",
      }),
    },
    watch: {
      "USER_ATTR_A": async function (val) {
        this.USER_ATTR_C = '';
        this.USER_ATTR_B = '';
        if (val == '') {
          this.dropB = [{CD_NM : "전체", CD : ""}];
        } else {
          this.dropB = await this.mixin_get_attr_by_attr('', val);
        }
      },
      "USER_ATTR_B": async function (val) {
        this.USER_ATTR_C = '';
        if (val == '') {
          this.dropC = [{USER_ID: '', USER_NM: '전체'}];
        } else {
          this.dropC = await this.mixin_get_user_by_attr(this.USER_ATTR_A, val);
        }
      }, 
      "DT_USER_ATTR_A": async function (val) {
        if (val != '') {
          this.dt_dropB = await this.mixin_get_attr_by_attr('', val);
        } else {
          this.dt_dropB = [];
        }
      },
    },
    methods: {
      async init(){
        this.setDataTableParams();
        this.dropGroupItems.push(...await this.mixin_user_auth_get());
        this.dropGroupItems2.push(...await this.mixin_user_auth_get());

        // 관리자 권한일때는 상세정보 콤보박스에 상담사,관리자 권한만 나오게
        if (this.geUserRole.atrtGroupId == '20220826113735368TWBJfPKh') {
          this.dropGroupItems2.forEach((item, idx) => {
            if(item.CD == '20190125141939798TWB52868') {
              this.dropGroupItems2.splice(idx, 1);
            }
          })
        }
        this.USER_ID_KEY = this.$store.getters['userStore/GE_USER_COMPANY'].userId;
      },

      //Table row 클릭이벤트
      getListSel(item, row) {
        if(item != null){
          this.activeRow(row,true);
        }
      },
  
      async activeRow(row, value){
        const item = row.item;

        this.dropA = await this.mixin_get_attr_by_attr('****', '');
        this.detail = item;
        // this.detail.HP_NO = this.mixin_setPhoneNo(this.detail.HP_NO)
        // this.detail.IPT_NO = this.mixin_setPhoneNo(this.detail.IPT_NO)

        console.log("this.detail  : " ,this.detail)
        this.regType = this.detail.REG_TYPE;

        this.detailInit();

        // 관리자 권한이고 선택한 사용자가 시스템관리자 권한일 경우 권한그룹 선택 불가
        if(this.geUserRole.atrtGroupId == '20220826113735368TWBJfPKh' && item.ATRT_GROUP_ID == '20190125141939798TWB52868') {
          this.atrt_disabled = true;
          this.dropGroupItems2 = [];
          this.dropGroupItems2.push(...await this.mixin_user_auth_get());
        } else if (this.geUserRole.atrtGroupId == '20190125141939798TWB52868') {
          this.atrt_disabled = false;
          this.dropGroupItems2 = [];
          this.dropGroupItems2.push(...await this.mixin_user_auth_get());
        } else {
          this.atrt_disabled = false;
          this.dropGroupItems2.forEach((item, idx) => {
            if(item.CD == '20190125141939798TWB52868') {
              this.dropGroupItems2.splice(idx, 1);
            }
          });
        }

        // 상세정보 수정가능한 컬럼
        this.DT_USER_ATTR_A = item.USER_ATTR_A;
        this.detailObj.USER_ID = item.USER_ID;
        this.detailObj.USER_ATTR_A = item.USER_ATTR_A;
        this.detailObj.USER_ATTR_B = item.USER_ATTR_B;
        this.detailObj.IPCC_NO = item.IPCC_NO;
        this.detailObj.CNNCT_IP = item.CNNCT_IP;
        this.detailObj.MAX_CHAT_AGENT = item.MAX_CHAT_AGENT;
        this.detailObj.BRCH_CD = item.BRCH_CD_CD;
        this.detailObj.REMOTE_ID = item.REMOTE_ID;
        this.detailObj.ATRT_GROUP_ID = item.ATRT_GROUP_ID;
        this.detailObj.UPD_ID = this.$store.getters['userStore/GE_USER_ROLE'].userId;
        this.detailObj.PROC_ID = this.$store.getters['userStore/GE_USER_ROLE'].userId;

        this.selectedRow = row;
        row.select(Boolean(value));
        row.isSelected = Boolean(value);
      },
  
      async getList(){
        this.dropA = await this.mixin_get_attr_by_attr('****', '');
  
        // 초기 인입시 관리자의 부서코드를 셋팅한다 (관리자 권한일 경우)
        if (this.geUserRole.atrtGroupId == '20220826113735368TWBJfPKh') {
          this.USER_ATTR_A = this.geUserRole.USER_ATTR_A;
          this.disabeld_A = true;
        } else {
          this.USER_ATTR_A = '';
        }
        this.USER_ATTR_B = "";
        this.USER_ATTR_C = "";
      },
  
      showAlert(){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: '회사구분란에서 회사를 선택후 이용해주십시오',
          iconClass: 'jh-alert-icon is-warning',
          type: 'default',
        })
      },
      search: function() {
        this.setDataTableParams();
        this.regType = '';
        
        this.dataTableOptions.load = this.searchUserInfo;

        if (this.dataTableOptions.page > 1) this.dataTableOptions.page = 1;
        else this.dataTableInstance.loadData();

      },
      setDataTableParams() {
        // let companyKey = this.ASP_NEWCUST_KEY;
        this.selGroupCd = [];
        this.selectedRow = undefined;
  
        // if(companyKey){
          this.requestData = {
            headers: {
              SERVICE: this.initHeaders.SERVICE,
              METHOD: this.initHeaders.METHOD,
              TYPE: this.initHeaders.TYPE,
            },
            sendData:{}
          };

          // header 세팅
          this.requestData.headers["URL"] = "/api/setting/agent/manage/list";
          this.requestData.headers["METHOD"] = "list";
  
          // sendData 세팅
          this.requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
          this.requestData.sendData["ATRT_GROUP_ID"] = this.ATRT_GROUP_ID;
          this.requestData.sendData["USER_ID"] = this.USER_ID;
          this.requestData.sendData["USER_NM"] = this.USER_NM;
          this.requestData.sendData["BRCH_CD"] = this.BRCH_CD;
          // this.requestData.sendData["USER_NICK"] = this.USER_NICK;
          // this.requestData.sendData["USE_YN"] = this.USE_YN;
          // this.requestData.sendData["LAST_LOGIN_DT_FROM"] = this.dates[0];
          // this.requestData.sendData["LAST_LOGIN_DT_TO"] = this.dates[1];
          // this.requestData.sendData["CNNCT_IP"] = this.CNNCT_IP;
          // this.requestData.sendData["ATRT_GROUP_ID"] = this.ATRT_GROUP_ID;
          // this.requestData.sendData["USER_ATTR_A"] = this.USER_ATTR_A;
          // this.requestData.sendData["USER_ATTR_B"] = this.USER_ATTR_B;
          // this.requestData.sendData["USER_ATTR_C"] = this.USER_ATTR_C;
          // this.requestData.sendData["SORT_ORDR"] = this.radioGroupSelected;
          // this.requestData.sendData["rdoOrderNm"] = this.rdoOrderNm;
          // this.requestData.sendData["rdoOrderDt"] = this.rdoOrderDt;
          // this.requestData.sendData["USER_ID_KEY"] = this.USER_ID_KEY;
          // this.requestData.sendData["A_USER_ID"] = this.A_USER_ID;
  
        // }else{
        //   this.showAlert();
        // }
      },
      async searchUserInfo(loadOptions){
        if (this.FIRST_INQRY_YN == "Y"){
          return this.FIRST_INQRY_YN = "N"
        }

        // header 세팅
        this.requestData.headers["ROW_CNT"] = loadOptions.itemsPerPage;
        this.requestData.headers["PAGES_CNT"] = loadOptions.page;

        return this.result(this.requestData);
      },
      getDetailListCallBack(response){
        console.log("response : ",response)
        
        let header = response.HEADER;
        let data = response.DATA;
        this.detailObj = {};
        this.detail = {};
        if(!this.mixin_isEmpty(response) && !header.ERROR_FLAG){
          if(!this.mixin_isEmpty(data) && data.length > 0) {
            for (let d of data) {
              for (let k of Object.keys(d)) {
                d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k];

                // console.log("d[k] : ", d[k])
                // console.log("Object.keys(d) : ", Object.keys(d))
                // console.log("Object.keys(d) TRUE OR FALSE : ", Object.keys(d) == "HP_NO")
              }
              // console.log("Object.keys(d) : ", d.HP_NO)
              d.USER_SSN_MASK = d.USER_SSN.substr(0, 6).concat("-").concat(d.USER_SSN.substr(6, 1)).concat("******");
              d.HP_NO = this.mixin_setPhoneNo(d.HP_NO)
              d.IPT_NO = this.mixin_setPhoneNo(d.IPT_NO)
              // d['index'] = parseInt(d.ROWNUM);
            }
          }

          // if(!this.mixin_isEmpty(data) && data.length > 0) {
          //   for(data)
          // }

          
          return {
            data: data,
            totalCount: header.TOT_COUNT
          };
        }else {
          this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
        }
      },
  
      userAdd(){
        this.E040106P01Param = {
          headerTitle: "상담사정보 신규등록",
          data: '',
          type: "I",
        }
        this.showDialog('E040106P01')
      },
  
      sendDetailInfo(){
        if( Object.keys(this.selGroupCd).length === 0 ) {
          this.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: true,
            msg: "선택된 항목이 존재하지 않습니다.",
            iconClass: "jh-alert-icon is-warning",
            type: "default",
          });
          return;
        }
        this.E040106P02Param = {
          headerTitle: "상담사 상세정보",
          data: this.selGroupCd[0],
          type: "U",
        }
        this.showDialog('E040106P02');
      },
  
      groupAdd(){
        if( Object.keys(this.selGroupCd).length === 0 ) {
          this.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: true,
            msg: "선택된 항목이 존재하지 않습니다.",
            iconClass: "jh-alert-icon is-warning",
            type: "default",
          });
          return;
        }

        this.E040106P03Param = {
          headerTitle: "상담사정보 소속등록",
          data: this.selGroupCd[0],
          type: "GU",
          dropA: this.dropA,
          dropB: this.dropB,
          dropC: this.dropC,
          dropD: this.dropD,
          ASP_NEWCUST_KEY : this.ASP_NEWCUST_KEY
        }
        this.showDialog('E040106P03')
      },
      async result(requestData){
        //api 호출 부분
        return api.post(requestData.headers.URL,   //api url입력
            //await axios.post('https://local.hkpalette.com:8443/'+this.URLData,{
            requestData.sendData
            , //api에서 사용될 data 입력
            {head: requestData.headers
            }) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
            .then((response) => {
              // string으로 넘어올 경우 에러임.
              if(typeof(response.data) === "string"){
                let responseData = JSON.parse(response.data.replaceAll("\n", ""));
                this.$store.commit("alertStore/openAlertDialog", {
                  alertDialogToggle: true,
                  msg: responseData.HEADER.ERROR_MSG,
                  iconClass: "jh-alert-icon is-warning",
                  type: "default",
                });
                return;
              }

              if (response.data.HEADER.ERROR_FLAG) {
                let msg = response.data.HEADER.ERROR_MSG
                this.common_alert(msg, "error");
                return;
              }
              
              if(response.data.HEADER.METHOD == "list" && requestData.headers.SERVICE == "setting.agent.manage"){
                return this.getDetailListCallBack(response.data);
              } else if (response.data.HEADER.passwordProcess == "passwordProcess") {
                this.passwordProcessCallBack(response.data);
              } else if (response.data.HEADER.METHOD == "process" && response.data.HEADER.DATA_FLAG == "reset") {
                this.resetPWDCallBack(response.data);
              } else if (response.data.HEADER.METHOD == "process" && response.data.HEADER.DATA_FLAG == "unlock") {
                this.unlockBtnCallBack(response.data);
              }
              
            }) //api 호출 성공 이후 수행
            .catch((err) => {
              alert(err);
            }) //api호출 에러 작업 수행
  
        //결과값 보여주기
      },
  
      startDate(e) {
        this.dates[0] = e;
      },
      endDate(e) {
        this.dates[1] = e;
      },
      showDialog(type) {
        this[`dialog${type}`] = true;
  
      },
      hideDialog(type) {
        this[`dialog${type}`] = false;
      },
      submitDialog(type) {
        console.log("완료");
        this.selGroupCd = [];
        this.dataTableInstance.loadData();
        this[`dialog${type}`] = false;
      },
      resetDialog(type) {
        console.log("reset");
        this.selGroupCd = [];
        this.dataTableInstance.loadData();
        this[`dialog${type}`] = false;
      },
      unlockDialog(type) {
        console.log("unlock");
        this.selGroupCd = [];
        this.dataTableInstance.loadData();
        this[`dialog${type}`] = false;
      },
      resetBtn() {
        this.passwordProcess("reset");
      },
      unlockBtn() {
        let requestData = {
          headers : {
            SERVICE: this.initHeaders.SERVICE,
            METHOD : this.initHeaders.METHOD,
            TYPE   : this.initHeaders.TYPE,
          },
          sendData: {}
        };

        // header 세팅
        requestData.headers["SERVICE"] = "setting.agent.manage.password-lock-initl";
        requestData.headers["URL"] = "/api/setting/agent/manage/password-lock-initl/process";
        requestData.headers["METHOD"] = "process";
        requestData.headers["DATA_FLAG"] = "unlock";

        //sendData 세팅

        //requestData.sendData["ASP_NEWCUST_KEY_DE"] = this.ASP_NEWCUST_KEY_DE;
        requestData.sendData["USER_NM"] = this.detail.USER_NM;
        requestData.sendData["USER_NICK"] = this.detail.USER_NICK;
        requestData.sendData["BRCH_CD"] = this.detail.BRCH_CD_CD;
        requestData.sendData["ATRT_GROUP_NM"] = this.detail.ATRT_GROUP_ID;
        requestData.sendData["CNNCT_IP"] = this.detail.CNNCT_IP;
        requestData.sendData["REGR_NM"] = this.detail.REGR_NM;
        requestData.sendData["VIEW_REG_DTTM"] = this.getDateFormat(this.detail.VIEW_REG_DTTM);
        requestData.sendData["AMDR_NM"] = this.detail.AMDR_NM;
        requestData.sendData["VIEW_UPD_DTTM"] = this.getDateFormat(this.detail.VIEW_UPD_DTTM);
        requestData.sendData["HP_NO"] = this.detail.HP_NO;
        requestData.sendData["PROC_ID"] = this.detail.PROC_ID; //최종수정자
        requestData.sendData["BIZ_DIV"] = 'RESETPWD';
        requestData.sendData["PWD_STATUS"] = 'NORMAL';
        requestData.sendData["USER_ID"] = this.detail.USER_ID;

        this.result(requestData);
      },
      saveBtn() {
        if(this.regType == '01') {
          this.saveDetail();  // 자동
        } else {
          this.saveManual();  // 수동
        }
      },
      async saveManual() {
        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: this.initHeaders.METHOD,
            TYPE: this.initHeaders.TYPE,
          },
          sendData: {}
        };

        requestData.headers['SERVICE'] = 'setting.agent.manage';
        requestData.headers['URL'] = '/api/setting/agent/manage/update-manual';
        requestData.headers['METHOD'] = 'update';

        const reg = /[^0-9]/g;

        requestData.sendData['USER_ID'] = this.detail.USER_ID.trim();
        requestData.sendData['USER_NM'] = this.detail.USER_NM;
        requestData.sendData['USER_ATTR_A'] = this.detail.USER_ATTR_A;
        requestData.sendData['USER_DUTY_NM'] = this.detail.USER_DUTY;
        requestData.sendData['EMPLOYED_YN'] = this.detail.EMPLOYED_YN_CD;
        requestData.sendData['EMAIL_ADDR'] = this.detail.EMAIL_ADDR;
        requestData.sendData['ENTCO_DT'] = this.detail.ENTCO_DT.replaceAll(reg, '');           // 입사일
        requestData.sendData['RETR_DT'] = this.detail.RETR_DT.replaceAll(reg, '');             // 퇴사일
        requestData.sendData['LEAVE_DT'] = this.detail.LEAVE_DT.replaceAll(reg, '');           // 휴직일
        requestData.sendData['REINSTATE_DT'] = this.detail.REINSTATE_DT.replaceAll(reg, '');   // 복직일
        requestData.sendData['ASSIGN_DT'] = this.detail.ASSIGN_DT.replaceAll(reg, '');         // 최종발령일
        requestData.sendData['REG_TYPE'] = "02";


        requestData.sendData['IPT_NO'] = this.detail.IPT_NO.replaceAll("-", "");


        let temp_act = this.encryptAES_ECB(this.detail.USER_ACT.replaceAll(" ", ""), process.env.VUE_APP_SSN_KEY);


        if(this.detail.USER_SSN_MASK == this.detail.USER_SSN.substr(0, 6).concat("-").concat(this.detail.USER_SSN.substr(6, 1)).concat("******")){
          this.detail.USER_SSN_MASK = this.encryptAES_ECB(this.detail.USER_SSN.replaceAll(" ", ""), process.env.VUE_APP_SSN_KEY);
        }else if(this.detail.USER_SSN_MASK.replaceAll("-", "").length != 13 || this.detail.USER_SSN_MASK.includes( '*' )){
          this.common_alert(`주민번호 수정란을 확인해주세요.`, 'error');
          return false;
        }else{
          this.detail.USER_SSN_MASK = this.encryptAES_ECB(this.detail.USER_SSN_MASK.replaceAll(" ", ""), process.env.VUE_APP_SSN_KEY);
        }
        
        requestData.sendData['USER_SSN'] = this.detail.USER_SSN_MASK;
        requestData.sendData['USER_ACT'] = temp_act;
        requestData.sendData["SSN_KEY"] = process.env.VUE_APP_SSN_KEY; //등록자

        requestData.sendData['HP_NO'] = this.detail.HP_NO.replaceAll("-", "");;
        requestData.sendData['CEN_TYPE_CD'] = this.detail.CEN_TYPE_CD;
        requestData.sendData['JISA'] = this.detail.JISA;
        requestData.sendData['JISUM'] = this.detail.JISUM;
        requestData.sendData['USER_BK'] = this.detail.USER_BK;
        requestData.sendData['USER_OP'] = this.detail.USER_OP;
        requestData.sendData['MEMO'] = this.detail.MEMO;


        const response = await this.common_postCall(requestData);

        if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
          this.saveDetail();
        }

      },
      async saveDetail() {
        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: this.initHeaders.METHOD,
            TYPE: this.initHeaders.TYPE,
          },
          sendData: {}
        };

        requestData.headers['SERVICE'] = 'setting.agent.manage';
        requestData.headers['URL'] = '/api/setting/agent/manage/update';
        requestData.headers['METHOD'] = 'update';

        requestData.sendData['UPD_ID'] = this.$store.getters['userStore/GE_USER_ID'];
        requestData.sendData['PROC_ID'] = this.$store.getters['userStore/GE_USER_ID'];
        requestData.sendData['USER_ID'] = this.detailObj.USER_ID;
        requestData.sendData['USER_ATTR_B'] = this.detailObj.USER_ATTR_B;
        requestData.sendData['IPCC_NO'] = this.detailObj.IPCC_NO;
        requestData.sendData['BRCH_CD'] = this.detailObj.BRCH_CD;
        requestData.sendData['CNNCT_IP'] = this.detailObj.CNNCT_IP;
        requestData.sendData['REMOTE_ID'] = this.detailObj.REMOTE_ID;
        requestData.sendData['MAX_CHAT_AGENT'] = this.detailObj.MAX_CHAT_AGENT;
        requestData.sendData['ATRT_GROUP_ID'] = this.detailObj.ATRT_GROUP_ID;

        const response = await this.common_postCall(requestData);

        if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
          this.common_alert(`정상적으로 수정되었습니다.`, 'done')
          this.dataTableInstance.loadData();
        }

      },
      async passwordProcess(DATA_FLAG) {
        let requestData = {
          headers : {
            SERVICE: this.initHeaders.SERVICE,
            METHOD : this.initHeaders.METHOD,
            TYPE   : this.initHeaders.TYPE,
          },
          sendData: {}
        };

        // header 세팅
        requestData.headers["SERVICE"] = "login/password-encpt";
        requestData.headers["URL"] = "/api/login/password-encpt/process";
        requestData.headers["METHOD"] = "process";
        requestData.headers["DATA_FLAG"] = DATA_FLAG;
        requestData.headers["passwordProcess"] = 'passwordProcess';

        this.result(requestData);
      },
      resetPWD(strEncryptKey, PWD, DATA_FLAG) {

        let requestData = {
          headers : {
            SERVICE: this.initHeaders.SERVICE,
            METHOD : this.initHeaders.METHOD,
            TYPE   : this.initHeaders.TYPE,
          },
          sendData: {}
        };

        // header 세팅
        requestData.headers["SERVICE"] = "setting.agent.manage.password-initl";
        requestData.headers["URL"] = "/api/setting/agent/manage/password-initl/process";
        requestData.headers["METHOD"] = "process";
        requestData.headers["DATA_FLAG"] = DATA_FLAG;

        //sendData 세팅
        //requestData.sendData["ASP_NEWCUST_KEY_DE"] = this.ASP_NEWCUST_KEY_DE;
        requestData.sendData["USER_NM"] = this.detail.USER_NM;
        requestData.sendData["USER_NICK"] = this.detail.USER_NICK;
        requestData.sendData["BRCH_CD"] = this.detail.BRCH_CD_CD;
        requestData.sendData["ATRT_GROUP_NM"] = this.detail.ATRT_GROUP_ID;
        requestData.sendData["CNNCT_IP"] = this.detail.CNNCT_IP;
        requestData.sendData["REGR_NM"] = this.detail.REGR_NM;
        requestData.sendData["VIEW_REG_DTTM"] = this.getDateFormat(this.detail.VIEW_REG_DTTM);
        requestData.sendData["AMDR_NM"] = this.detail.AMDR_NM;
        requestData.sendData["VIEW_UPD_DTTM"] = this.getDateFormat(this.detail.VIEW_UPD_DTTM);
        requestData.sendData["HP_NO"] = this.detail.HP_NO;
        requestData.sendData["PROC_ID"] = this.detail.PROC_ID; //최종수정자
        requestData.sendData["NEW_PWD"] = PWD;
        requestData.sendData["ENCRYPT_KEY"] = strEncryptKey;
        requestData.sendData["BIZ_DIV"] = 'RESETPWD';
        requestData.sendData["PWD_STATUS"] = 'RESET';
        requestData.sendData["USER_ID"] = this.detail.USER_ID;

        this.result(requestData);

      },
      resetPWDCallBack(response) {
        let header = response.HEADER;
        let data = response.DATA[0];

        let iconType = 'done';
        let msg = "비밀번호가 초기화되었습니다.";

        //alert창 띄우기
        this.common_alert(msg, iconType);
        this.$emit('reset');
      },
      unlockBtnCallBack(response) {
        let header = response.HEADER;
        let data = response.DATA[0];

        //alert창 설정
        let iconType = 'done';
        let msg = "잠금 해제 되었습니다.";

        //alert창 띄우기
        this.common_alert(msg, iconType);
        this.$emit('unlock');
      },
      //암호화
      common_aesEncrypt(strPassWD, strEncryptKey) {
        return gibberish.aesEncrypt(strPassWD, strEncryptKey);
      },
      passwordProcessCallBack(response) {
        let header = response.HEADER;
        let DATA_FLAG = header.DATA_FLAG;
        let strEncryptKey = response.DATA[0].ENCRYPT_KEY;
        let strPassWD = '';
        if (strEncryptKey === "") {
          return;
        }

        //생성된 암호화 키 정보를 사용하여 ID와 password필드값을 AES 256byte 알고리즘을 이용하여 암호화한다.
        if (DATA_FLAG == "I") {
          strPassWD = '1111';
          // strPassWD = this.USER_ID;
        } else {
          strPassWD = '1111';
          // strPassWD = this.detail.USER_ID;
        }
        let PWD = this.encryptAES_ECB(strPassWD, strEncryptKey);

        if (DATA_FLAG == "U") {
          this.updateDetail(PWD, DATA_FLAG);
        } else if (DATA_FLAG == "I") {
          this.userAdd(PWD, DATA_FLAG, strEncryptKey);
        } else if (DATA_FLAG == "reset") {
          this.resetPWD(strEncryptKey, PWD, DATA_FLAG);
        }

    },
      detailInit() {
        this.detailObj = {};
        this.DT_USER_ATTR_A = '';
      },
      getDateFormat(value) {
        if (value) {
          let dateObj = value.split('/');
          let timeObj = value.split(':');
          let resultDate = dateObj[0] + dateObj[1] + dateObj[2].substr(0, 2) + timeObj[0].substr(0, -2) + timeObj[1]
          return resultDate;
        } else {
          return value;
        }

      },
      changeDept(val) {
        this.DT_USER_ATTR_A = val;
      },
      async getRegType() {
        const arr = await this.mixin_common_code_get_all(['REG']);
        this.regArr = arr[0].group_value;
      },
      changeRegType(val) {
        this.regType = val;
      },
      ctiLogout() {
        if (this.selectedRow != undefined) {
          this.common_confirm("CTI 로그아웃 하시겠습니까?", async ()=>{
            const item = this.selectedRow.item;
            eventBus.ctiSendReq('userSignOUt', item.USER_ID);
          });
        } else {
          this.common_alert('사용자를 선택해주세요.');
        }
      },
      async getSelBoxList() {
        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: this.initHeaders.METHOD,
            TYPE: this.initHeaders.TYPE,
          },
          sendData: {}
        };

        requestData.headers["URL"] = "/api/code/common/code-book/selectCode";
        requestData.headers["METHOD"] = "selectCode";
        requestData.headers["ASYNC"] = false;

        let sendList = [];
        sendList.push("EMPLOYED"); //재직여부

        requestData.sendData["GROUP_CD"] = sendList

        const response = await this.common_postCall(requestData);

        if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
          for (var i = 0; i < response.DATA.length ; i++){
            if (response.DATA[i].GROUP_CD == "EMPLOYED"){
              this.employed.push(response.DATA[i]);
            }
          }
        }
      },
      userDelete() {
        this.common_confirm("처리하시겠습니까?", async () => {
          const userId = this.selectedRow.item.USER_ID;

          let requestData = {
            headers: {
              SERVICE: this.initHeaders.SERVICE,
              METHOD: this.initHeaders.METHOD,
              TYPE: this.initHeaders.TYPE,
            },
            sendData: {}
          }

          requestData.headers["URL"] = "/api/setting/agent/manage/delete";
          requestData.headers["METHOD"] = "delete";
          requestData.headers["ASYNC"] = false;

          requestData.sendData["USER_ID"] = userId;

          const response = await this.common_postCall(requestData);

          if(this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
            this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error');
            return false;
          } else {
            this.common_alert(`정상처리 되었습니다.`);
            this.dataTableInstance.loadData();
          }
        });
        
      },
    }
    
  };
  </script>
  
  <style>
  </style>